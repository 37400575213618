.dateInput {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.dataInput {
    width: 50%;
    height: 100%;
}

.dataInputDisabled {
    width: 50%;
    height: 100%;
    background-color: var(--grey-200);
}

.helperText {
    font-size: 2rem;
    margin-top: 5px;
    font-size: large;
}