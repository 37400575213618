:root {
    --primary-400: #fbd090;
    --primary-500: #f9af4c;
    --primary-600: #f7941d;
    --primary-700: #f58500;
    --primary-800: #f37600;

    --grey-100: #EEEEEE;
    --grey-200: #E0E0E0;
    --grey-300: #c9c7c7;
    --grey-400: #bdbdbd;
    --grey-500: #9e9e9e;
    --grey-600: #404040;

    --white: #fff;
    --black: #000;

    --newStatus: #FF4A78;
    --inProgressStatus: #FCDF3A;
    --doneStatus: #000000;

    --default-black: #0A0A0A;
}
