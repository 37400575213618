.container {
    display: flex;
    min-height: 100vh;
    background-color: var(--grey-100);
    flex-direction: column;

}

.header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* height: fit-content; */
    width: 100%;
    /* margin: 1rem; */
    text-transform: uppercase;
    background-color: var(--grey-100);
}

.title {
    font-size: 2rem;
    font-weight: 600;
    margin-left: 1rem;
    text-transform: capitalize;
}

.accordionContainer {
    display: flex;
    flex-direction: column;
    width: 70vw;
    margin: 3rem;

}
