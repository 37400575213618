.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.primarySide {
    display: flex;
    background-color: var(--primary-500);
    align-items: center;
    justify-content: center;
    padding: 0 6%;
    font-size: 1.1rem;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10%;
}

.title {
    font-weight: 700;
    margin-top: 4%;
}

.description {
    text-align: justify;
    font-size: 1rem;
    margin-bottom: 5%;
    /* overflow: scroll; */
}

.whiteSide {
    background-color: var(--grey-100);
    display: flex;
    align-items: center;
    justify-content: center;
}
