.logo {
    max-width: 230px;
    width: 100%;
}

.logout {
    display: flex;
    font-size: 1.5rem;
    font-weight: 700;
    cursor: pointer;
}
