.labels {
  /* margin: 3%; */
  color: black;
}

.icons {
  margin: 3% 5% 0 3%;
}

.labels p {
  text-align: justify;
  font-size: large;
}

.labels h2 {
  text-align: justify;
  font-weight: 600;
}

.labelsInfo h2 {
  text-align: justify;
  font-weight: 700;
}

.labelsInfo {
  margin: 0 5% 3% 0%;
  color: black;
}

.labelsInfoh2 {
  margin: 3% 5% 0% 5%;
  font-weight: bold;
  color: black;
}

.tableHeaders {
  font-weight: 700
}

.rowStyle {
  color: black;
  font-weight: 700;
  height: 3.5vw;
  vertical-align: middle !important;
}

.title {
  font-weight: 700;
  color: black;
  font-size: 1.8rem;

}

.subtitle {
  font-weight: 700;
  color: black;
  font-size: 1.4rem;
}