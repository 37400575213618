.container {
    display: flex;
    min-height: 100vh;
    background-color: var(--grey-100);
    flex-direction: column;
}

.header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* height: fit-content; */
    width: 100%;
    /* margin: 1rem; */
    text-transform: uppercase;
    background-color: var(--grey-100);
    margin: 10px 0;
}

.title {
    font-size: 2rem;
    font-weight: 600;
    margin-left: 1rem;
    text-transform: capitalize;
}

.keycodeContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgb(255, 255, 255);
    border-radius: 25px;
    width: 430px;
    font-size: 1.7rem;
    font-weight: 600;
    padding: 10px 20px 10px 50px;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 15px 10px -7px;
    margin: 4rem 0
}

.copyIcon {
    color: black;
    cursor: pointer;
}

.copyIcon:active {
    color: rgb(0, 146, 24);
    cursor: pointer;
}

.copiedIcon {
    color: rgb(0, 146, 24);
}

.contentBold {
    font-weight: 600;
    color: black;
    font-size: 1.4rem;
    margin: 1.4rem 0;
}

.contentParagraph {
    color: black;
    margin-bottom: 4rem;
}
