.dropzone {
  border: 2px dashed #999;
  border-radius: 5px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.activeDropzone {
  border: 2px solid #4caf50;
}

.dropzone:hover {
  border: 2px solid #4caf50;
}


.dialog {
  width: 90%;
  /* height: 80%; */
  max-width: 800px;
  max-height: 600px;
}