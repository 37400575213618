.label {
  color: #000000;
  font-weight: 700;
}

.labelDisabled {
  color: var(--grey-400);
  font-weight: 700;
}

.textFieldDisabled {
  background-color: var(--grey-200);
}