.commentTitle {
  font-size: 1.7rem;
  font-weight: 600;
  color: black;
  margin-bottom: 10px;

}

.commentContainer {
  width: 100%;
  height: fit-content;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  border: 1px solid var(--grey-400);
  padding: 15px;
}

.dateStyle {
  font-size: 1rem;
  margin-left: 10px;
  color: var(--grey-600);
}

.usernameStyle {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--grey-800);
}