.containerText {
    font-size: 1rem;
    font-weight: 500;
    margin: 10px;
    display: flex;
}

.textField {
    width: 90%;
}

.textFieldDisabled {
    width: 90%;
    background-color: var(--grey-200);
}