.subTitle {
  color: #000000;
  font-weight: 700;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  color: black;
  padding-top: 2.5rem;
  margin-bottom: 1rem;
}

.textField {
  width: 40%;
  overflow: hidden;
}

/* .textField :focus {
  width: 100%;
  background-color: white;
} */

.disabledTextField {
  width: 40%;
  background-color: var(--grey-200);
}